import(/* webpackMode: "eager" */ "/usr/src/app/packages/ui/launchgood-com/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/packages/ui/launchgood-com/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"jakarta\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/packages/ui/launchgood-com/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/packages/ui/launchgood-com/src/app/root-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteWideFreshDesk"] */ "/usr/src/app/packages/ui/launchgood-com/src/components/FreshDeskChat/SiteWideFreshDesk.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OptinMonster"] */ "/usr/src/app/packages/ui/launchgood-com/src/components/ThirdParty/OptinMonster.tsx");
